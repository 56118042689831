import styles from "./simple-menu-item.module.css";

export const SimpleMenuItem = ({ data, onClick }) => {
    const { properties } = data;

    let simpleMenuItemStyle =
        properties?.BGColor?.startGradient && properties?.BGColor?.endGradient
            ? { background: `linear-gradient(180deg, ${properties.BGColor.startGradient} 0%, ${properties.BGColor.endGradient} 100%)` }
            : {};

    const descriptionStyle =
        properties?.textDescriptionColor
            ? { color: properties.textDescriptionColor }
            : {};

    return <section className={styles.simpleMenuItem} onClick={onClick} style={simpleMenuItemStyle}>
        <div className={styles.styleWrapper}>
            <div className={styles.menuInformation}>
                <span className={styles.menuLabel}>{data.title}</span>
                <span className={styles.menuDescription} style={descriptionStyle}>{data.description}</span>
            </div>
            {data.img && <img className={styles.menuImage} src={`img/${data.img}`} alt="" />}
        </div>
    </section>
};