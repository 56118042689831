import { useLocation, useNavigate } from "react-router";
import styles from "./header.module.css";
import { useCallback, useContext } from "react";
import { BreadCrumbs } from "../bread-crumbs/bread-crumbs";
import { AppContext } from "../app/app";

export const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { crumbs } = useContext(AppContext);

    const onClickBackButton = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onClickLogo = useCallback(() => {
        if (location.pathname !== '/') {
            navigate(`/`, { state: { previousLocation: location } });
        }
    }, [location, navigate]);

    const onClickHomePage = onClickLogo;

    return <section className={styles.header}>
        <div className={styles.headerContainer}>
            <div className={styles.leftColunnContainer}>
                {location.state?.previousLocation && <div className={styles.backButtonContainer}>
                    <button className={`${styles.backButton} ${styles.controlButtons}`} onClick={onClickBackButton}>
                        <img src={'img/back.png'} alt="назад" />
                    </button>
                    <span className={styles.backButtonLabel}>назад</span>
                </div>}
            </div>
            <div className={styles.centerColunnContainer}>
                <div className={styles.logoContainer} onClick={onClickLogo}>
                    <img src={'img/logo.png'} alt="лого" />
                </div>
            </div>
            <div className={styles.rightColunnContainer}>
                {location.state?.previousLocation && <div className={styles.homePageButtonContainer}>
                    <button className={`${styles.homePageButton} ${styles.controlButtons}`} onClick={onClickHomePage}>
                        <img src={'img/home_page.png'} alt="назад" />
                    </button>
                    <span className={styles.homePageButtonLabel}>на главную</span>
                </div>}
            </div>
        </div>
        <BreadCrumbs crumbs={crumbs} />
    </section>
}