import styles from './courier-services-page.module.css';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../components/app/app';

export const CourierServicesPage = () => {
    const [data, setData] = useState(null);
    const { setCrumbs } = useContext(AppContext);

    const onClickButton = useCallback(() => {
        const platform = window.Telegram.WebApp.platform;
        if (platform === "android" || platform === "ios") {
            window.open(`tel:${data.courierNumber}`);
        } else {
            try {
                window.Telegram.WebApp.showAlert(data.courierNumberDesktop);
            } catch (e) {
                alert(data.courierNumberDesktop);
            }
        }
    }, [data]);

    useEffect(() => {
        setCrumbs([]);
        fetch(`data/courier-services.json`).then(resp => resp.json()).then(data => setData(data));
    }, [setCrumbs])

    if (!data) {
        return null;
    }

    return <section className={styles.courierServicesPage}>
        <div className={styles.styleWrapper}>
            <div className={styles.label}>Курьер химчистки</div>
            <img className={styles.icon} src='img/services/delivery.png' alt='' />
            <div className={styles.description}>{"Доставка и забор \n заказов"}</div>
            <div className={styles.price}>{`${data.price} р.`}</div>
            <div className={styles.list}>
                {data.services.map((item, index) => {
                    return (
                        <div className={styles.listItem} key={index}>
                            <sapn className={styles.point} />
                            <span>{item}</span>
                        </div>
                    )
                })}
            </div>
            <button className={styles.button} onClick={onClickButton}>
                <div className={styles.buttonStyleWrapper}>
                    Вызвать курьера
                </div>
            </button>
        </div>
    </section>;
}   