import styles from "./list-services.module.css";

export const ListServices = ({ data }) => {
    return <section className={styles.listServices}>
        {data.map((item, index) => {
            return <div key={index} className={styles.listItem}>
                <span className={styles.title}>{item.title}</span>
                <span className={styles.priceBlock}>
                    <span className={styles.price}>{`${item.price}р.`}</span>
                    <span>/{item.measure}.</span>
                </span>
            </div>
        })}
    </section>
}