import { useContext, useEffect, useState } from 'react';
import styles from './services-menu-page.module.css';
import { useLocation, useNavigate } from 'react-router';
import { AppContext } from '../../components/app/app';
import { ServicesMenuItem } from '../../components/services-menu-item/services-menu-item';

export const ServiceMenuPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [menuData, setMenuData] = useState(null);
    const { setCrumbs } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 1), 100);
        setTimeout(() => window.scrollTo(0, 0), 150);
    }, []);

    useEffect(() => {
        setCrumbs(["Наши услуги"]);
        fetch(`data/services-menu.json`).then(resp => resp.json()).then(data => setMenuData(data));
    }, [setCrumbs])

    if (!menuData) {
        return null;
    }

    return <section className={styles.servicesMenuPage}>
        {menuData.map((item, index) => {
            return <div key={index} className={styles.sectionGrid}>
                <ServicesMenuItem
                    title={item.title}
                    img={item.img}
                    onClick={() => navigate(`/services/${item.servicesId}`, { state: { previousLocation: location } })}
                />
            </div>
        })}
    </section>;
};