import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./home-page.module.css";
import { SimpleMenuItem } from "../../components/simple-menu-item/simple-menu-item";
import { useLocation, useNavigate } from "react-router";
import { AppContext } from "../../components/app/app";

export const HomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [menuData, setMenuData] = useState(null);
    const { setCrumbs } = useContext(AppContext);

    useEffect(() => {
        setCrumbs([]);
        fetch(`data/home-page.json`).then(resp => resp.json()).then(data => setMenuData(data));
    }, [setCrumbs])

    const addClickHandler = useCallback(({ id, properties }) => {
        switch (id) {
            case "reception_points":
                return () => {
                    navigate("/cities", { state: { previousLocation: location } });
                }
            case "services":
                return () => {
                    navigate("/services", { state: { previousLocation: location } });
                }
            case "order_status":
                return () => {
                    window.Telegram.WebApp.openTelegramLink("https://t.me/renzachi_bot");
                }
            case "courier_service":
                return () => {
                    navigate("/courier-services", { state: { previousLocation: location } });
                }
            default:
                return null;
        }
    }, [location, navigate]);

    if (menuData === null) {
        return null;
    }

    return <section className={styles.homePage}>
        {menuData.map((item) => {
            return <SimpleMenuItem
                key={item.id}
                data={item}
                onClick={addClickHandler(item)}
            />
        })}
    </section>;
};