import styles from "./bread-crumbs.module.css";

export const BreadCrumbs = ({ crumbs }) => {
    if (!Array.isArray(crumbs) || crumbs.length === 0) {
        return null;
    }
    return <div className={styles.breadCrumbs} >
        {crumbs.length > 1 && <span className={styles.firstPart} >
            {`${crumbs.slice(0, crumbs.length - 1).join(" / ")} / `}
        </span>}
        <span className={crumbs.length === 1 ? styles.firstPart : styles.secondPart} >{crumbs[crumbs.length - 1]}</span>
    </div>;
}