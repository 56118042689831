import { useParams } from "react-router";
import styles from "./map-page.module.css";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../components/app/app";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

export const MapPage = () => {
    const { id } = useParams();
    const [geometry, setGeometry] = useState(null);
    const { setCrumbs } = useContext(AppContext);

    useEffect(() => {
        fetch(`/data/id-reception-centers.json`)
            .then(async resp => {
                try { return await resp.json(); } catch (e) { return []; }
            })
            .then(data => {
                const cityId = data?.find(item => item.id === id)?.cityId;
                if (cityId) {
                    fetch(`/data/reception-centers/${cityId}.json`)
                        .then(async resp => {
                            try { return await resp.json(); } catch (e) { return []; }
                        })
                        .then(data => {
                            const receptionCenter = data?.find(item => item.id === id);
                            if (receptionCenter) {
                                setCrumbs(["Приемный пункт", receptionCenter.address])
                                setGeometry(receptionCenter.geometry)
                            }
                        })
                }
            });
    }, [id, setCrumbs]);

    if (!geometry) {
        return null;
    }

    return <section className={styles.mapPage}>
        <YMaps>
            <Map width="100%" height="100%" state={{ center: geometry, zoom: 14 }} >
                <Placemark
                    defaultGeometry={geometry}
                />
            </Map>
        </YMaps>
    </section>
}