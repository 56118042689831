import { useContext, useEffect, useState } from "react";
import styles from "./reception-centers-page.module.css";
import { ReceptionCentersMenu } from "../../components/reception-centers-menu/reception-centers-menu";
import { useLocation, useNavigate, useParams } from "react-router";
import { AppContext } from "../../components/app/app";
import { publishId } from "../../settings";

export const ReceptionCentersPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { city } = useParams();
    const [listData, setListData] = useState(null);
    const { setCrumbs } = useContext(AppContext);

    useEffect(() => {
        fetch(`/data/reception-centers/${city}.json?{publishId=${publishId}}`).then(async resp => {
            try {
                return await resp.json()
            } catch {
                return null;
            }
        }).then(data => setListData(data));
        fetch(`/data/cities.json?{publishId=${publishId}}`).then(resp => resp.json()).then(data => {
            const cityInfo = data?.find(item => item.id === city);
            if (cityInfo) {
                setCrumbs(["Приемные пункты", cityInfo.cityName])
            }
        });
    }, [city, setCrumbs])

    if (listData === null) {
        return null;
    }

    return <section className={styles.receptionCentersPage}>
        <div className={styles.styleWrapper}>
            {listData.map((item) => {
                return <ReceptionCentersMenu
                    key={item.id}
                    data={item}
                    onMapButtonClick={() => navigate(`/maps/${item.id}`, { state: { previousLocation: location } })}
                />
            })}
        </div>
    </section>;
}