import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/app/app.jsx';
import "./index.css";
import { HashRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.hash.match(/^#tgWebAppData/)) {
  window.location.hash = '#';
}

root.render(
  <Router>
    <App />
  </Router>
);