import { Header } from '../header/header';
import { HomePage } from '../../pages/home-page/home-page';
import { Route, Routes } from 'react-router';
import { CitiesReceptionCentersPages } from '../../pages/cities-reception-centers-page/cities-reception-centers-page';
import { ReceptionCentersPage } from '../../pages/reception-centers-page/reception-centers-page';
import styles from './app.module.css';
import { createContext, useState } from 'react';
import { ServiceMenuPage } from '../../pages/services-menu-page/services-menu-page';
import { ListServicesPage } from '../../pages/list-services-page/list-services';
import { MapPage } from '../../pages/map-page/map-page';
import { CourierServicesPage } from '../../pages/courier-services-page/courier-services-page';
export const AppContext = createContext(null);

export const App = () => {
  const [crumbs, setCrumbs] = useState([]);

  return (
    <div className={styles.app}>
      <AppContext.Provider value={{
        crumbs,
        setCrumbs
      }}>
        <Header />
        <Routes >
          <Route path='/' element={<HomePage />} />
          <Route path='/cities' element={<CitiesReceptionCentersPages />} />
          <Route path='/cities/:city' element={<ReceptionCentersPage />} />
          <Route path='/services' element={<ServiceMenuPage />} />
          <Route path='/services/:id' element={<ListServicesPage />} />
          <Route path='/maps/:id' element={<MapPage />} />
          <Route path='/courier-services' element={<CourierServicesPage />} />
          <Route path="*" element={<span>404</span>} />
        </Routes>
      </AppContext.Provider>
    </div >
  );
}
