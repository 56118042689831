import { useParams } from "react-router";
import styles from "./list-services.module.css";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../components/app/app";
import { ListServices } from "../../components/list-services/list-services";
import { publishId } from "../../settings";

export const ListServicesPage = () => {
    const { id } = useParams();
    const [listData, setListData] = useState(null);
    const { setCrumbs } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 1), 100);
        setTimeout(() => window.scrollTo(0, 0), 150);
    }, []);

    useEffect(() => {
        fetch(`/data/services.json`).then(async resp => {
            try {
                return await resp.json()
            } catch {
                return null;
            }
        }).then(data => setListData(data.find((item => item.id === id)).services ?? null));
        fetch(`/data/services-menu.json?{publishId=${publishId}}`).then(resp => resp.json()).then(data => {
            const servicesInfo = data?.find(item => item.servicesId === id);
            if (servicesInfo) {
                setCrumbs(["Наши услуги", servicesInfo.title])
            }
        });
    }, [id, setCrumbs]);
    if (!listData || !Array.isArray(listData)) {
        return null;
    }

    return <section className={styles.listServicesPage}>
        <div className={styles.styleWrapper}>
            <ListServices data={listData} />
        </div>
    </section>;
}