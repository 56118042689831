import { Fragment } from "react";
import styles from "./working-days-schedule.module.css";

export const WorkingDaysSchedule = ({ data }) => {
    if (!Array.isArray(data)) {
        return null;
    }

    return <section className={styles.workingDaysSchedule}>
        {data.map((item, index) => <Fragment key={index}>
            <div className={styles.firstColumn}>{item.days}</div>
            <div className={styles.secondColumn}>{item.time}</div>
        </Fragment>
        )}
    </section>
};