import { useCallback } from "react";
import { WorkingDaysSchedule } from "../working-days-schedule/working-days-schedule";
import { WorkingDaysScheme } from "../working-days-scheme/working-days-scheme";
import styles from "./reception-centers-menu.module.css";

export const ReceptionCentersMenu = ({ data, onMapButtonClick }) => {
    const { address, workingDaysScheme, workingDaysSchedule, additionalServices, contactNumber } = data;
    const platform = window.Telegram.WebApp.platform;

    const onNumberClick = useCallback((number) => {
        if (platform === "android" || platform === "ios") {
            window.open(`tel:${number.replace(/[^0-9+]/g, "")}`);
        }
    }, [platform]);

    return <section className={styles.receptionCentersMenu}>
        <div className={styles.address}>{address}</div>
        {additionalServices && <div className={styles.additionalServices}>
            {additionalServices.map((item, index) => <span key={index}>{`+ ${item}`}</span>)}
        </div>}
        <div className={styles.workDays}>
            <WorkingDaysScheme data={workingDaysScheme} />
            <WorkingDaysSchedule data={workingDaysSchedule} />
        </div>
        <div className={styles.contactNumbers} >
            {contactNumber.map((item, index) =>
                <span className={styles.contactNumber} key={index} onClick={() => onNumberClick(item)}>
                    {item}{index !== contactNumber.length - 1 && ", "}
                </span>)}
        </div>
        <button className={styles.mapButton} onClick={onMapButtonClick}>
            <div className={styles.mapButtonStyleWrapper}>
                Открыть карту
            </div>
        </button>
    </section>
};
