import { useCallback } from "react";
import styles from "./working-days-scheme.module.css";

export const WorkingDaysScheme = ({ data }) => {
    const getColorItem = useCallback((status) => {
        switch (status) {
            case 1:
                return styles.fullDay
            case 0:
                return styles.halfDay
            default:
                return styles.nonWorkingDay
        }
    }, [])

    if (!Array.isArray(data)) {
        return null;
    }

    return <section className={styles.workingDaysScheme}>
        {data.map((item, index) => <div className={`${getColorItem(item)} ${styles.dayStatus}`} key={index}></div>)}
    </section>;
}