import { useContext, useEffect, useState } from "react";
import styles from "./cities-reception-centers-page.module.css";
import { SimpleMenuItem } from "../../components/simple-menu-item/simple-menu-item";
import { useLocation, useNavigate } from "react-router";
import { AppContext } from "../../components/app/app";

const useDescription = () => {
    const [citiesData, setCitiesData] = useState(null);

    useEffect(() => {
        fetch('data/cities.json')
            .then(async resp => {
                try { return await resp.json() } catch (e) { return null }
            })
            .then(async cities => {
                if (cities) {
                    const cityDataBuffer = new Map();
                    for (const city of cities) {
                        await fetch(`data/reception-centers/${city.id}.json`)
                            .then(async resp => {
                                try { return await resp.json() } catch (e) { return null }
                            })
                            .then(data => {
                                if (data) {
                                    cityDataBuffer.set(city.id, data);
                                }
                            });
                    }
                    setCitiesData(cityDataBuffer);
                }
            });
    }, []);

    return citiesData;
}

const getDescription = (citiesData, cityId) => {
    const cityData = citiesData?.get(cityId);

    if (!cityData) {
        return "";
    }

    const cityDataLength = cityData.length;
    const doubleEnding = cityDataLength > 100 ? cityDataLength % 100 : cityDataLength;
    const ending = cityDataLength > 10 ? cityDataLength % 10 : cityDataLength;

    if (doubleEnding > 10 && doubleEnding < 20) {
        return `${cityDataLength} приемных пунктов`;
    }

    if (ending === 1) {
        return `${cityDataLength} приемный пункт`;
    } else if (ending > 1 && ending < 5) {
        return `${cityDataLength} приемных пункта`;
    } else {
        return `${cityDataLength} приемных пунктов`;
    }
}

export const CitiesReceptionCentersPages = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [menuData, setMenuData] = useState(null);
    const { setCrumbs } = useContext(AppContext);
    const descriptionsData = useDescription();

    useEffect(() => {
        setCrumbs(["Приемные пункты", "выберите город"]);
        fetch(`data/cities.json`).then(resp => resp.json()).then(data => setMenuData(data));
    }, [setCrumbs])

    if (menuData === null) {
        return null;
    }

    return <section className={styles.citiesReceptionCentersPages}>
        {menuData.map((item) => {
            return <SimpleMenuItem
                key={item.id}
                data={{ ...item, description: getDescription(descriptionsData, item.id) }}
                onClick={() => navigate(`/cities/${item.id}`, { state: { previousLocation: location } })}
            />
        })}
    </section>;
}